import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import Words from "./words";

export default function App() {
  const [day, setDay] = useState();
  const [answer, setAnswer] = useState();
  const [guess, setGuess] = useState();
  const [guesses, setGuesses] = useState([]);

  useEffect(() => {
    const startDate = new Date("2022-04-25");

    const answers = {
      "2022-05-05": "pivot",
      "2022-05-06": "loopy",
      "2022-05-07": "puree",
      "2022-05-08": "knock",
      "2022-05-09": "ethos",
      "2022-05-10": "focal",
      "2022-05-11": "rivet",
      "2022-05-12": "crime",
      "2022-05-13": "reach",
      "2022-05-14": "space",
      "2022-05-15": "toxic",
      "2022-05-16": "ought",
      "2022-05-17": "pleat",
      "2022-05-18": "glute",
      "2022-05-19": "cling",
      "2022-05-20": "adapt",
      "2022-05-21": "oomph",
      "2022-05-22": "scuff",
      "2022-05-23": "cynic",
      "2022-05-24": "moose",
      "2022-05-25": "vault",
      "2022-05-26": "detox",
      "2022-05-27": "flint",
      "2022-05-28": "ghost",
      "2022-05-29": "lunar",
      "2022-05-30": "croak",
      "2022-05-31": "abode",
      "2022-06-01": "bingo",
      "2022-06-02": "rogue",
      "2022-06-03": "snarl",
      "2022-06-04": "whisk",
      "2022-06-05": "reset",
      "2022-06-06": "plane",
      "2022-06-07": "adage",
      "2022-06-08": "rapid",
      "2022-06-09": "vista",
      "2022-06-10": "incur",
      "2022-06-11": "unity",
      "2022-06-12": "bongo",
      "2022-06-13": "climb",
      "2022-06-14": "short",
      "2022-06-15": "growl",
      "2022-06-16": "habit",
      "2022-06-17": "limbo",
      "2022-06-18": "mercy",
      "2022-06-19": "taste",
      "2022-06-20": "tract",
      "2022-06-21": "suede",
      "2022-06-22": "ounce",
      "2022-06-23": "morse",
      "2022-06-24": "flyer",
      "2022-06-25": "elbow",
      "2022-06-26": "smash",
      "2022-06-27": "chess",
      "2022-06-28": "vocal",
      "2022-06-29": "wryly",
      "2022-06-30": "overt",
      "2022-07-01": "first",
      "2022-07-02": "lunge",
      "2022-07-03": "lunch",
      "2022-07-04": "lunet",
      "2022-07-05": "lupus",
      "2022-07-06": "lyric",
      "2022-07-07": "witch",
      "2022-07-08": "humid",
      "2022-07-09": "verse",
      "2022-07-10": "claim",
      "2022-07-11": "width",
      "2022-07-12": "clasp",
      "2022-07-13": "clamp",
      "2022-07-14": "chord",
      "2022-07-15": "smoke",
      "2022-07-16": "virus",
      "2022-07-17": "abide"
    };

    setDay(getDay(startDate));
    setAnswer(answers[new Date().toISOString().split("T")[0]]);
  }, []);

  const handleSubmit = (values, { resetForm }) => {
    const guess = values["guess"].toLowerCase();

    setGuess(guess);
    setGuesses([...guesses, guess]);

    resetForm();
  };

  const validateGuess = (value) => {
    let error;

    const guess = value.toLowerCase();

    if (guess.length !== 5) {
      return "must be 5 characters length";
    }

    if (Words.indexOf(guess) === -1) {
      return "not in word list";
    }

    return error;
  };

  const composeShareText = () => {
    return `
      abcdle ${day}

      Total guesses: ${guesses.length}
    `;
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({ text: composeShareText() });
    } else {
      navigator.clipboard.writeText(composeShareText());
    }
  };

  const aboveGuesses = (guesses) => {
    return (
      [...guesses]
        .sort()
        // .reverse()
        .filter((guess) => guess > answer)
    );
  };

  const belowGuesses = (guesses) => {
    return (
      [...guesses]
        .sort()
        // .reverse()
        .filter((guess) => guess < answer)
    );
  };

  const getDay = (startDate) => {
    const timeDelta = Date.now() - startDate;
    const daysDelta = timeDelta / (1000 * 3600 * 24);

    return Math.ceil(daysDelta);
  };

  return (
    <>
      <h1>abcdle {day}</h1>
      <ul>
        <li>guess the 5 letter word</li>
        <li>⬆️ the word is towards a</li>
        <li>⬇️ the word is towards z</li>
      </ul>
      <hr />
      {guesses.length > 0 && <p>Total guesses: {guesses.length}</p>}
      {guesses && (
        <ul>
          {belowGuesses(guesses).map((guess) => {
            return (
              <li key={guess}>
                {guess} ({`⬇️ ${Words.indexOf(answer) - Words.indexOf(guess)}`})
              </li>
            );
          })}
        </ul>
      )}
      {guess !== answer && (
        <Formik
          initialValues={{
            guess: ""
          }}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, resetForm }) => (
            <Form>
              <Field
                id="guess"
                name="guess"
                placeholder=""
                validate={validateGuess}
              />
              <ErrorMessage name="guess" />
              <button type="submit">Submit</button>
            </Form>
          )}
        </Formik>
      )}
      {guess === answer && (
        <p>
          {guess} - Well done you got it in {guesses.length}!{" "}
          <button onClick={handleShare}>Share</button>
        </p>
      )}
      {guesses && (
        <ul>
          {aboveGuesses(guesses).map((guess) => {
            return (
              <li key={guess}>
                {guess} ({`⬆️ ${Words.indexOf(guess) - Words.indexOf(answer)}`})
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}
